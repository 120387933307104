import { Icon, Stack, StackItem, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import i18n from "../../services/i18n";
import { getFooterClassNames } from "./Footer.styles";
import { IFooterProps, IFooterState } from "./Footer.types";
export class FooterComponent extends React.Component<
  IFooterProps,
  IFooterState
> {
  render(): JSX.Element {
    const { styles, environment } = this.props;
    const [classNames] = getFooterClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const version: string = environment?.itemID ?? "2.0.8";
    const html: string = `&centerdot;  ${i18n
      .t("global:copyrights")
      .replace("2021", new Date().getFullYear().toString())} ${
      this.props.mobile ? "&centerdot;<br />" : ""
    } &centerdot; ${version} &centerdot;`;
    return (
      <div className={classNames.root}>
        <Stack horizontal grow className={classNames.footerContainer}>
          <StackItem>
            <div></div>
          </StackItem>
          <StackItem grow>
            <span dangerouslySetInnerHTML={{ __html: html }}></span>
            {environment && environment.iconName && (
              <TooltipHost content={environment.title}>
                <Icon {...environment} className={classNames.environment} />
                {!this.props.mobile && (
                  <span
                    dangerouslySetInnerHTML={{ __html: "&centerdot;" }}
                  ></span>
                )}
                {!this.props.mobile && <Text>{environment.title}</Text>}
              </TooltipHost>
            )}
          </StackItem>
          <StackItem>
            <div></div>
          </StackItem>
        </Stack>
      </div>
    );
  }
}
